<template>
	<div class="button-link">
		<a
		class="project-link"
		v-if="link" 
		:href="link" 
		target="_blank">
		<span>Check it out</span>
		<svg width="13px" height="10px" viewBox="0 0 13 10">
				<path d="M1,5 L11,5"></path>
				<polyline points="8 1 12 5 8 9"></polyline>
			</svg>
		</a>
	</div>
</template>

<script>

export default {
	name: 'Button',
	components: {
	},
	props: {
		link: String,
	},
}
</script>

<style lang="scss">
.project-link {
	position: relative;
	margin: auto;
	padding: 19px 22px;
	transition: all .2s ease;
	&:before {
		content: "";
		position: absolute;
		top: 16px;
		left: 0;
		display: block;
		border-radius: 10px;
		background: $main-1;
		width: 2px;
		height: 30px;
		transition: all .1s ease;
	}
	span {
		color: $alt-color;
		position: relative;
		font-size: 16px;
		line-height: 18px;
		letter-spacing: .25em;
		text-transform: uppercase;
		vertical-align: middle;
	}
	svg {
		position: relative;
		top: 0;
		margin-left: 10px;
		fill: none;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke: $alt-color;
		stroke-width: 2;
		transform: translateX(-5px);
		transition: all .3s ease;
	}
	&:hover{
		&:before{
			width: 100%;
		}
		&:active {
			transform: scale(.96);
		}
	}
}
</style>
