<template>
	<div class="menu">
		<MenuItems/>
	</div>
</template>

<script>
import MenuItems from '@/components/globals/partials/MenuItems'
import router from '@/router'

export default {
	name: 'Menu',
	components: {
		MenuItems
	},
	mounted() {
		let w = window.innerWidth
		if (w > 800) {
			router.push({ path: '/about' })
		}
	}
}
</script>

<style scoped lang="scss">
.menu {
	position:fixed;
	left: 0;
	top:0;
	width:100vw;
	height: 100vh;
	background-color: $primary-color-light;
}
</style>
