<template>
	<div class="projects">
		<div class="work-nav">
			<ProjectsNav/>
		</div>
		<Main/>
	</div>
</template>

<script>
import Main from '@/components/projects/Main'
import ProjectsNav from '@/components/projects/partials/ProjectsNav'

export default {
	name: 'Projects',
	components: {
		Main,
		ProjectsNav
	},
	mounted() {
	}
}
</script>

<style lang="scss">
.projects {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	.work-nav {
		display: flex;
	}
}
</style>
