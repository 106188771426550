<template>
	<div class="main">
		<ul class="timeline">
			<li class="timeline-item">
				<div class="timeline-info">
					<span>January 2021 - Present</span>
				</div>
				<div class="timeline-marker"></div>
					<div class="timeline-content">
						<h3 class="timeline-title">Creative Technologist - Brand Experience</h3>
						<p>Helped build and organize an entirely new department from the ground up.</p>
						<p>Leveraged AI, XR, WebGL, and web 3.0 technology in client projects.</p>
						<p>Prototyped and developed augmented experiences for mobile devices.</p>
						<p>Streamlined internal workflows through the use of technology.</p>
						<p>Educated clients on best practices for ideas within the Metaverse.</p>
						<p>Planned, organized, and ran internal training on new and emerging technology.</p>
					</div>
			</li>
			<li class="timeline-item">
					<div class="timeline-info">
						<span>October 2020 - January 2021</span>
					</div>
					<div class="timeline-marker"></div>
					<div class="timeline-content">
						<h3 class="timeline-title">Creative Technologist - Tech Innovation</h3>
						<p>Found creative applications for emerging technology.</p>
						<p>Rapidly prototyped proof of concepts to help sell ideas to clients.</p>
					</div>
			</li>
			<li class="timeline-item period">
				<div class="timeline-info"></div>
			</li>
			<li class="timeline-item">
				<div class="timeline-info">
					<span>July 2018 - October 2020</span>
				</div>
				<div class="timeline-marker"></div>
				<div class="timeline-content">
					<h3 class="timeline-title">Digital Generalist - Studio</h3>
					<p>Media - Trafficked ad campaigns in Google Adwords and DCM.</p>
					<p>Organized data presentations for media campaigns and site analytics. </p>
					<p>Performed QA across all of our web properties.</p>
				</div>
			</li>
			<li class="timeline-item">
				<div class="timeline-info">
					<span>May 2018</span>
				</div>
				<div class="timeline-marker"></div>
				<div class="timeline-content">
					<h3 class="timeline-title">Graduated from University of North Carolina Wilmington</h3>
					<p>Bachelor's Degree in Business Marketing</p>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>

export default {
	name: 'About',
	components: {
	},
	mounted() {

	}
}
</script>

<style scoped lang="scss">
.timeline {
	text-align: left;
	line-height: 1.4em;
	list-style: none;
	margin: 0;
	padding-top:40px;
	width: 100%;
	h1, h2, h3, h4, h5, h6 {
		line-height: inherit;
	}
}

.timeline-item {
	padding-left: 40px;
	position: relative;
	&:last-child {
		padding-bottom: 0;
	}
}

.timeline-info {
	font-size: 12px;
	letter-spacing: 3px;
	margin: 0 0 .5em 0;
	text-transform: uppercase;
	white-space: nowrap;
}

.timeline-marker {
	position: absolute;
	top: 0; bottom: 0; left: 0;
	width: 15px;
	&:before {
		background: white;
		border-radius: 100%;
		content: "";
		display: block;
		height: 20px;
		width: 20px;
		z-index: 1000;
		position: absolute;
		top: 3px; left: -2.5px;
		transition: background 0.3s ease-in-out,
		border 0.3s ease-in-out;
	}
	&:after {
		content: "";
		width: 3px;
		background: #CCD5DB;
		display: block;
		position: absolute;
		top: 20px; bottom: -4px; left: 6px;
	}
	.timeline-item:last-child &:after {
		content: none;
	}
}

.timeline-content {
	padding-bottom: 40px;
	p:last-child {
		margin-bottom: 0;
	}
}

.period {
	padding: 0;
	.timeline-info {
		display: none;
	}
	.timeline-marker {
	&:before {
		background: transparent;
		content: "";
		width: 15px;
		height: auto;
		border: none;
		border-radius: 0;
		top: 0;
		bottom: 30px;
		position: absolute;
		border-top: 3px solid #CCD5DB;
		border-bottom: 3px solid #CCD5DB;
	}
	&:after {
		content: "";
		height: 32px;
		top: auto;
	}
	}
	.timeline-content {
		padding: 40px 0 70px;
	}
	.timeline-title {
		margin: 0;
	}
}
</style>
