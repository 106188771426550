<template>
	<div id="nav">
		<Navigation/>
	</div>
	<router-view v-slot="{ Component }">
		<transition
		:name="transitionName"
		mode="out-in">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
import Navigation from '@/components/globals/Navigation'
const DEFAULT_TRANSITION = 'slide-fade';
import magicMouse from 'magicmouse.js'

export default {
	name: 'App',
	components: {
		Navigation
	},
	data() {
		return {
			prevHeight: 0,
			transitionName: 'slide',
		};
	},
	mounted() {
			let options = {
			"cursorOuter": "circle-basic",
			"hoverEffect": "pointer-blur",
			"hoverItemMove": false,
			"defaultCursor": false,
			"outerWidth": 25,
			"outerHeight": 25
		};
    magicMouse(options);
	},
	created() {
	this.$router.beforeEach((to, from, next) => {
		let transitionName = to.meta.transitionName || from.meta.transitionName;
		if (transitionName === 'slide') {
			const toDepth = to.path.split('/').length;
			const fromDepth = from.path.split('/').length;
			transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
		}
		this.transitionName = transitionName || DEFAULT_TRANSITION;
		next();
	});
 },
}
</script>

<style lang="scss">
body {
	background-color: $primary-color;
	color: $alt-color;
	display: flex;
	justify-content: center;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	width: 85%;
	height: 100vh;
	max-width:1000px;
}

#nav {
	padding-top: 50px;
	display: flex;
	justify-content: space-between;
}

.overflow-hidden {
	overflow-y: hidden;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
	opacity:1;
	transition-duration: 0.5s;
	transition-property: height, opacity, transform;
	transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
	overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
	opacity: 0;
	transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
	opacity: 0;
	transform: translate(-2em, 0);
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter-to {
	transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	opacity: 1;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
