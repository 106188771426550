<template>
	<div >
		<div data-scroll-section class="oad-container">
			<div
			class="image-container"
			v-for="image in images" 
			:key="image.index">
			<img
			class="oad-image"
			data-scroll
			data-scroll-repeat
			data-scroll-class="hey"
			:src="image.pathLong">
			</div>
			<div class="individual-video" v-for="video in videos" :key="video.index">
				<video class="video" playsinline autoplay loop muted>
					<source :src="video.pathLong" type="video/mp4">
				</video>
		</div>
	</div>
	</div>
</template>

<script>
import LocomotiveScroll from 'locomotive-scroll'

export default {
	name: 'Main',
	data() {
		return {
			images: [
			],
			videos: [
			]
		};
	},
	computed: {
		fetchComplete: () =>{
			return true
		}
	},
	async mounted() {
		// Used to gather all images ni the OAD folder
		await this.importAllImg(require.context('@/assets/img/work/OAD', true, /\.png|.jpg|.gif$/));
		await this.importAllVid(require.context('@/assets/img/work/OAD', true, /\.mp4$/));
		// this.shuffle(this.images)
		// this.shuffle(this.videos)
		// const _self = this;
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll2 = new LocomotiveScroll()
      // _self.initLocoScroll();
    });
	},
	methods: {
		initLocoScroll() {
      const _self = this;

      this.scroll = new LocomotiveScroll({
        el: _self.$refs['scrollSections'],
        smooth: true,
        smoothMobile: true,
        getDirection: true
      });
    },
		importAllImg(r) {
			r.keys().forEach(key => (this.images.push({ pathLong: r(key), pathShort: key })));
		},
		importAllVid(r) {
			r.keys().forEach(key => (this.videos.push({ pathLong: r(key), pathShort: key })));
		},
		shuffle(array) {
			for (var i = array.length - 1; i > 0; i--) {
				var j = Math.floor(Math.random() * (i + 1));
				var temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.hey {
	opacity: 1 !important;
}

.oad-container {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	animation: 3s ease-in 1s 2 reverse both paused slide-in;
	.oad-image {
		width:275px;
		height:275px;
		object-fit: cover;
		border-radius: 25px;
		overflow: hidden;
		padding: 10px;
		transition: 1s;
		opacity: 0;
	}
	.video {
		border-radius: 25px;
		object-fit: cover;
		width:275px;
		height:275px;
		overflow: hidden;
		padding: 10px;
	}
}
</style>
