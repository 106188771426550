<template>
	<div data-scroll-container>
	<!-- <Curtains/> -->
	<div class="project-container">
		<div 
		v-for="project in this.projects" 
		:key="project.index">
			<Tile
			class="magic-hover"
			:projectTitle=project.title
			:projectImg=project.img
			:projectHeadline=project.headline
			:projectTags=project.tags
			:projectLink=project.link
			:projectDescription=project.description
			:additionalProjects=project.additional
			/>
		</div>
	</div>
	</div>
</template>

<script>
import Tile from '@/components/projects/partials/Tile'
import { projects } from '@/assets/data/projects'

export default {
	name: 'Main',
	components: {
		Tile,
		// Curtains,
	},
  data() {
    return {
      projects: projects
    };
  },
}
</script>

<style scoped lang="scss">
.project-container {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
	animation: 3s ease-in 1s 2 reverse both paused slide-in;
}

.individual-project {
	transform: translateY(50px);
	opacity: 0;
	transition: 2s;
}

.appear {
	transform: translateY(0px);
	opacity: 1;
}
</style>
