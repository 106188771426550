<template>
	<div class="social-icons">
		<a href="https://twitter.com/iAMKunkel" target=_blank>
			<img src="@/assets/img/logos/social_twitter.svg">
		</a>
		<a href="https://instagram.com/aleckunkel_/" target=_blank>
			<img src="@/assets/img/logos/social_instagram.svg">
		</a>
		<a href="https://linkedin.com/in/aleckunkel/" target=_blank>
			<img src="@/assets/img/logos/social_linkedin.svg">
		</a>
		<a href="https://angel.co/u/alec-kunkel" target=_blank>
			<img src="@/assets/img/logos/social_angellist.svg">
		</a>
	</div>
</template>

<script>
export default {
	name: 'SocialIcons',
}
</script>

<style scoped lang="scss">
.social-icons {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	width:100%;
	img {
		width: 20px;
		padding-right: 35px;
		transition: .2s;
		&:hover {
			width:25px;
		}
	}
}
</style>