<template>
	<div class="projects">
		<div class="work-nav">
			<DailiesNav/>
		</div>
		<Main/>
	</div>
</template>

<script>
import Main from '@/components/projects/dailies/Main'
import DailiesNav from '@/components/projects/partials/DailiesNav'

export default {
	name: 'Projects',
	components: {
		Main,
		DailiesNav
	}
}
</script>

<style lang="scss">
.projects {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	.work-nav {
		width: 100%;
		display: flex;
	}
}
</style>
