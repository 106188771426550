<template>
	<div class="navigation">
    <router-link to="/">
      <img class="header-logo magic-hover magic-hover__square" src="@/assets/img/site-identity/logo.svg">
    </router-link>
    <div class="mobile-nav">
      <HamburgerIcon/>
    </div>
    <div class="desktop-nav">
      <MenuItems/>
    </div>
	</div>
</template>

<script>
import HamburgerIcon from '@/components/globals/partials/HamburgerIcon'
import MenuItems from '@/components/globals/partials/MenuItems'

export default {
	name: 'Navigation',
	components: {
    HamburgerIcon,
    MenuItems
	}
}
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  z-index: 1000;
  .header-logo{
    &:hover{
      animation: shake 1s ease-in;
    }
  }
  img {
    height: 40px;
  }
  .mobile-nav {
    display: none;
    @media screen and (max-width: $screen-portrait-max) {
      display: block;
    }
  }
  .desktop-nav {
    display: block;
    @media screen and (max-width: $screen-portrait-max) {
      display: none;
    }
  }
}
</style>
