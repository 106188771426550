<template>
	<div data-scroll-section class="social-icons">
		<div 
		data-scroll
		data-scroll-class="visible"
		class="hidden">
		<a href="https://twitter.com/iAMKunkel" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_twitter.svg">
		</a>
		<a href="https://instagram.com/aleckunkel_/" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_instagram.svg">
		</a>
		<a href="https://linkedin.com/in/aleckunkel/" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_linkedin.svg">
		</a>
		<a href="https://angel.co/u/alec-kunkel" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_angellist.svg">
		</a>
		<a href="https://aleckunkel.medium.com/" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_medium.svg">
		</a>
		<a href="https://www.producthunt.com/@iamkunkel" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_producthunt.svg">
		</a>
		<a href="https://github.com/iamkunkel" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_github.svg">
		</a>
		<a href="https://dribbble.com/AlecKunkel" target=_blank>
			<img class="magic-hover" src="@/assets/img/logos/social_dribbble.svg">
		</a>
		</div>
	</div>
</template>

<script>
import LocomotiveScroll from 'locomotive-scroll'

export default {
	name: 'SocialIcons',
	mounted() {
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll = new LocomotiveScroll()
      // _self.initLocoScroll();
    });
	},
}
</script>

<style scoped lang="scss">
.hidden {
	opacity:0;
	transition: 1s;
}

.visible {
	opacity: 1 !important;
}

.social-icons {
	display: flex;
	flex-flow: wrap;
	align-items: center;
	width:100%;
	img {
		width: 20px;
		padding-top: 20px;
		padding-right: 35px;
		transition: .2s;
		&:hover {
			width:25px;
		}
	}
}
</style>