<template>
	<div class="project">
		<div
		data-scroll-section
		@click="$refs.sideBar.openModal()">
			<div 
			data-scroll
			data-scroll-repeat
			data-scroll-class="hey"
			class="project-content">
				<div class="magic-hover project-content-container">
					<h1>{{ projectTitle }}</h1>
					<p>{{ projectHeadline }}</p>
					<div class="project-tag-container">
						<p class="project-tag" v-for="item in projectTags" :key="item.index">
							{{ item }}
						</p>
					</div>
				</div>
			</div>
			<img
			data-scroll
			data-scroll-repeat
			data-scroll-class="image-reveal"
			@click="$refs.sideBar.openModal()"
			class="tile-img"
			:src=projectImg />
		</div>
			<modal
			class="sidebar"
			ref="sideBar">

				<template v-slot:header>
				<p class="project-title">{{ projectTitle }}</p>
				</template>

				<template v-slot:gallery>
					<img
					class="sidebar-img"
					:src=projectImg />
				</template>

				<template v-slot:body>
					<p class="project-description">{{ projectDescription }}</p>
					<div>
						<ButtonLink 
						:link="projectLink"
						/>
					</div>
					<div class="project-list" v-if="additionalProjects">
						<div class="project-mult" v-for="item in additionalProjects" :key="item.index">
							<p
							class="project-title"
							v-if="item.projectTitle">{{ item.projectTitle }}</p>
							<img v-if="item.projectImg" class="sidebar-img" :src="item.projectImg"/>
							<video class="sidebar-video" muted playsinline autoplay loop v-if="item.projectVid">
								<source :src="item.projectVid" type="video/mp4">
							</video>
							<p class="project-description" v-if="item.projectDescription">{{ item.projectDescription }}</p>
							<ButtonLink 
							:link="item.projectLink"
							/>
						</div>
					</div>
				</template>

			</modal>
	</div>
</template>

<script>
import Modal from '@/components/projects/partials/WorkSidebar'
import ButtonLink from '@/components/globals/ButtonLink'
import LocomotiveScroll from 'locomotive-scroll'

export default {
	name: 'Projects',
	components: {
		Modal,
		ButtonLink
	},
	props: {
		projectTitle: String,
		projectImg: String,
		projectHeadline: String,
		projectTags: Array,
		projectDescription: String,
		projectLink: String,
		additionalProjects: Object,
	},
	methods: {
	},
	mounted() {
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll3 = new LocomotiveScroll()
      // _self.initLocoScroll();
    });
	},
}
</script>

<style scoped lang="scss">
.hey {
	opacity: 1 !important;
}

.image-reveal {
	opacity: .7 !important;
}

.project {
	position: relative;
	height: 500px;
	width: 280px;
	margin: 10px;
	padding-bottom: 15px;

	.tile-img {
		opacity: 0;
		transition: 1s;
		left: 0;
		z-index: -100;
		position: absolute;
		height: 100%;
		width: 100%;
		object-fit: cover;
		border-radius: 25px;
		overflow: hidden;
	}

	.project-content {
		width: 90%;
		height: 100%;
		bottom: 0;
		margin: 5%;
		text-align: left;
		position: absolute;
		z-index: 100;
		transition: 1s;
		opacity: 0;
		&-container {
			bottom: 0;
			position: absolute;
		}
		&:hover {
			transform: scale(1.03);
		}
		h1 {
			font-size:20px;
			padding-bottom: 5px;
		} 
		.project-tag-container {
			display:flex;
			.project-tag {
				background-color: $main-1;
				padding:5px;
				margin-right: 5px;
				margin-top: 10px;
				border-radius:5px;
				font-size: 13px;
			}
		}
	}
	.sidebar{
		.project-mult {
			padding-top: 25px;
		}
		.project-title{
			text-align: left;
			font-size: 30px;
			font-weight: bold;
			padding-bottom:5px;
			width: 400px;
			@media screen and (max-width: $screen-portrait-max) {
				width: 100%;
			}
		}
		.project{
			&-description {
				margin-bottom: 20px;
				font-size: 15px;
				width: 400px;
				@media screen and (max-width: $screen-portrait-max) {
					width: 100%;
				}
			}
		}
		&-img {
			border-radius: 10px;
			object-fit: cover;
			height: 400px;
			width: 400px;
			margin-bottom: 25px;
			margin-top: 10px;
			@media screen and (max-width: $screen-portrait-max) {
				width: 100%;
			}
		}
		&-video {
			border-radius: 10px;
			object-fit: cover;
			height: 400px;
			width: 400px;
			margin-bottom: 25px;
			margin-top: 10px;
			@media screen and (max-width: $screen-portrait-max) {
				width: 100%;
			}
		}
	}
}
</style>
