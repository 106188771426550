<template>
	<div @click="toggleMenu" class="hamburger-icon magic-hover">
      <div class="line-menu half start"></div>
      <div class="line-menu"></div>
      <div class="line-menu half end"></div>
	</div>
</template>

<script>
import router from '@/router'

export default {
	name: 'Hamburger Menu',
	components: {
	},
  watch: {
  },
  methods: {
    toggleMenu() {
      let wrapperMenu = document.querySelector('.hamburger-icon');

      if (this.$store.getters.getMenuStatus === false) {
        wrapperMenu.classList.toggle('open');
        this.$store.commit('mutateMenuStatus', true)
        router.push({ path: '/menu' })
      }
      else {
        wrapperMenu.classList.toggle('open');
        this.$store.commit('mutateMenuStatus', false)
        router.push({ path: this.$store.getters.getPreviousRoute })
      }
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.hamburger-icon {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 330ms ease-out;
}

.hamburger-icon.open {
  transform: rotate(-45deg);  
}

.line-menu {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 3px;
}

.line-menu.half {
  width: 50%;
}

.line-menu.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right;
}

.open .line-menu.start {
  transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left;
}

.open .line-menu.end {
  transform: rotate(-90deg) translateX(-3px);
}

</style>
