<template>
	<div class="home">
		<Main/>
		<SocialIcons/>
	</div>
</template>

<script>
import Main from '@/components/home/Main'
import SocialIcons from '@/components/home/SocialIcons'


export default {
	name: 'Home',
	components: {
		Main,
		SocialIcons,
	},
	mounted() {
	}
}
</script>

<style lang="scss">
.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	margin-top: 10vh;
	justify-content: center;
}

</style>
