import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'


import Menu from '../views/Menu'
import Home from '../views/Home'
import About from '../views/About'
import Projects from '../views/Projects'
import Dailies from '../views/Dailies'
import Contact from '../views/Contact'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
	{
		path: '/menu',
		name: 'Menu',
		component: Menu
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact
	},
	{
		path: '/projects',
		name: 'Projects',
		component: Projects
	},
	{
		path: '/dailies',
		name: 'Dailies',
		component: Dailies
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from) => {
	store.commit('mutatePreviousRoute', from.fullPath)
})

export default router
