export const projects = [
	{
		"title": "Let's House Durham",
		"img":require('@/assets/img/work/LetsHouseDurham/house_img_001.png'),
		"headline": "If every person in Durham donates just $15.76, we can house all those affected by homelessness in the city for an entire year.",
		"tags":['Client', 'Web Dev'],
		"description": "Hard to believe, but it's true: If every single person in Durham County donated just $15.76, we could provide housing for all our neighbors experiencing homelessness for an entire year. We did the math!",
		"link": "https://letshousedurham.org/",
	},
	{
		"title": "The Worst House on the Internet",
		"img":require('@/assets/img/work/WorstHouse/wh_img_001.png'),
		"headline": "A fun experience to celebrate spooky season.",
		"tags":['Experiment', 'Web Dev'],
		"description": "Halloween is one of my favorite holidays. The combination of fall weather and scary movies just ties together so nicely. To kick off the season 2021, I helped launch The Worst House on the Internet. An experience that lets you trick-or-treat online and get a terrible treat. Whether it be an old and dying meme or a picture of Alec Baldwin signed by a guy named Alec (yes we did actually ship things out)",
		"link": "https://theworsthouseontheinternet.com/",
	},
	{
		"title": "Camera Offset Project",
		"img":require('@/assets/img/work/CameraOffset/co_img_001.png'),
		"headline": "Finding an excuse to go camera off during video calls and save the world.",
		"tags":['Experiment', 'Web Dev'],
		"description": "After over a year of nonstop video calls I, and a few coworkers, were over it. It is for that reason we launched Camera Offset Project. Did you know just by turning off your video you cut the C02 emissions of your video call by 96%? Crazy right! And also the perfect justification for keeping your video off. Download a thumbnail from CameraOffsetProject.com and set it as your profile picture.",
		"link": "https://www.cameraoffsetproject.com/",
	},
	{
		"title": "The Gigapizza",
		"img":require('@/assets/img/work/GigaPizza/gp_img_001.gif'),
		"headline": "A message hidden in an image of pizza spanding a billion pixels.",
		"tags":['Client', 'Web Dev'],
		"description": "Little Caesars pretzel crust is a truly loved LTO menu item. There are die hard fans who celebrate its arrival every year. To kick off 2021, Little Caesars gave these fans a way to unlock pretzel crust early. All fans had to do was find a code hidden across the country and internet. One code in particular was hidden in this Gigapizza: a billion pixel image of a pizza.",
		"link": "",
	},
	{
		"title": "Unreal Engine Demos",
		"img":require('@/assets/img/work/UnrealDemos/ue_img_001.jpg'),
		"headline": "A collection of vertical slices I've made in UE4",
		"tags":['Experiment', 'UE4'],
		"description": "",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: 'Super McKinney Cart',
				projectVid: require('@/assets/img/work/UnrealDemos/ue_img_003_1.mp4'),
				projectDescription: 'This vertical slice is a four-player racing game featuring my co-workers. Choose between eight cars and eight employees and compete against your friends',
				projectLink: ''
			},
			proj3: {
				projectTitle: 'Unreal CEO',
				projectVid: require('@/assets/img/work/UnrealDemos/ue_img_002.mp4'),
				projectDescription: 'My first vertical slice, this timed platformer lets you race against the clock to get the cake.',
				projectLink: ''
			},
		}
	},
	{
		"title": "Hot Pizza Date",
		"img":require('@/assets/img/work/HotPizzaDate/hpd_img_001.gif'),
		"headline": "Think Tinder but more pizza.",
		"tags":['Client', 'Web Dev'],
		"description": "A simple idea but at rapid speed. Think Tinder but with pizza. This site was built overnight in response to a Little Caesars tweet.",
		"link": "https://www.hotpizzadate.com/",
	},
	{
		"title": "Generate an Ape",
		"img":require('@/assets/img/work/GenerateAnApe/ape_img_001.png'),
		"headline": "Using machine learning to troll the most popular NFT community.",
		"tags":['Experiment', 'AI/ML', 'Web Dev'],
		"description": "While I'm a huge fan of the defi community, there are often moments where it seems to not make any sense. What I'm referring to here is the Bored Ape Yacht Club. An NFT community that spends way too much on images of apes. To have some fun with it, I scraped the entire BAYC website for every image. I then trained a style GAN on these images. The end product? Generate an Ape.",
		"link": "https://www.generateanape.com/",
	},
	{
		"title": "NGPF Finance Games",
		"img":require('@/assets/img/work/NGPF/ng_img_001.jpg'),
		"headline": "Teaching finance to kids in new and innovative games.",
		"tags":['Client', 'Web Dev', 'QA'],
		"description": "Next Gen Personal Finance is an organization devoted to teaching financial literacy to students. They provide some amazing resources through their site and I was fortunate to help make some of those. The following games were each made to teach a specific concepts to students. Games ranged from from teaching people how to invest to how to start a business.",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: 'Build Your Stax',
				projectImg: require('@/assets/img/work/NGPF/ng_img_002.jpg'),
				projectDescription: 'Investing simulations are an important part in school teachings, but they have always left out other types of investments. Stax was designed to teach that stocks are not the only investment type. Live out 20 years and take a stab at build your stax.',
				projectLink: 'https://buildyourstax.com/'
			},
			proj2: {
				projectTitle: 'Money Magic',
				projectImg: require('@/assets/img/work/NGPF/ng_img_003.jpg'),
				projectDescription: 'Budgeting can be a pretty dull topic, but it doesnt have to be! With Money Magic you step into the role of Enzos tour manager. Learn the ins and outs of budgeting while you help Enzo save for the performance of a lifetime.',
				projectLink: 'https://playmoneymagic.com/'
			},
			proj3: {
				projectTitle: 'Shady Sam',
				projectImg: require('@/assets/img/work/NGPF/ng_img_004.jpg'),
				projectDescription: 'Shady Sam was designed to teach kids the difficult concept of loan terms. While playing, you are put into the role of the lender and encouraged to find the worst loan for those in need. It is a dog eat dog world out there, and after playing, the hope is you do not end up on the wrong side of the crosshairs.',
				projectLink: 'https://shadysam.com/'
			},
			proj4: {
				projectTitle: 'Credit Clash',
				projectImg: require('@/assets/img/work/NGPF/ng_img_005.jpg'),
				projectDescription: 'With Credit Clash, we aimed to turn building credit into a cut throat game card game. Its a mad dash to open lines of credit and make payments on time to order to take home the win!',
				projectLink: 'https://creditclash.com/'
			},
			proj5: {
				projectTitle: 'Influencd',
				projectImg: require('@/assets/img/work/NGPF/ng_img_006.jpg'),
				projectDescription: 'Influenc’d is designed to give an idea of what it’s like to be an entrepreneur, through the lens of being an influencer.',
				projectLink: 'https://playinfluencd.com/'
			},
		}
	},
	{
		"title": "Crush it 4 Climate",
		"img":require('@/assets/img/work/CrushIt/crushit_img_001.png'),
		"headline": "Join the Outdoor State as we take on challenges all March long, to get stoked and get educated on fighting climate change to protect our playgrounds.",
		"tags":['Client', 'Design', 'Strategy & Userflow'],
		"description": "",
		"link": "https://protectourwinters.org/crushit/",
	},
	{
		"title": "Netflex",
		"img":require('@/assets/img/work/Netflex/nf_img_001.jpg'),
		"headline": "A way to get you moving while you're watching your favorite show.",
		"tags":['Experiment', 'Web Dev'],
		"description": "As the pandemic forced the closure of gyms and Tiger King launched on Netflix, a team at McKinney saw an opening. A tool that lets you work out, while you watch your favorite show. After downloading the extension and enabling, whenever you put on your favorite content, you get presented with a workout routine while you watch.",
		"link": "https://chrome.google.com/webstore/detail/netflex/hfanmigfcphpeagamjjombcbpgafhpkf/",
	},
	{
		"title": "Gunlock Bot",
		"img":require('@/assets/img/work/GunlockBot/glb_img_001.png'),
		"headline": "Spreading the importance of a buying a gun lock.",
		"tags":['Twitter Bot', 'Web Dev', 'Node'],
		"description": "Gary the Gunlock is a bot I built . The bot worked by pulling in tweets from a user and analyzing both text and images to determine if the tweet was about a gun lock. If it was, the bot would tweet back to the user recommending they purchase a gun lock. The bot was built using Node.js and the Twitter API.",
	},
	{
		"title": "FacePunch.Health",
		"img":require('@/assets/img/work/FacePunch/fp_img_001.png'),
		"headline": "Because sometimes you just need to punch a face.",
		"tags":['Experiment', 'Web Dev', 'Three.js'],
		"description": "Maybe it was a bad day, maybe it was my love for Three.js, but either way FacePunch.Health was created to help release a bit of your aggression. Upload a face and start punching.",
		"link": "https://facepunch.netlify.app/",
	},
	{
		"title": "ThreeJS Demos",
		"img":require('@/assets/img/work/ThreeJSDemos/three_img_000.jpg'),
		"headline": "A collection of Three.JS demos.",
		"tags":['Experiment', 'Web Dev', 'Three.js'],
		"description": "Here is a collection of Three.JS projects that were minor in scale. They each had a specific technique I wanted to explore or figure out.",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: 'My Office in 3D',
				projectImg: require('@/assets/img/work/ThreeJSDemos/three_img_001.jpg'),
				projectDescription: 'One of my favorite projects. This room fully modeled, textured, and baked in Blender.',
				projectLink: 'https://myoffice.netlify.app/'
			},
			proj2: {
				projectTitle: 'Minimal Bake',
				projectImg: require('@/assets/img/work/ThreeJSDemos/three_img_002.jpg'),
				projectDescription: 'An early prototype within my Three.JS journey. It was a small room with lightning fully baked in.',
				projectLink: 'https://minimalbake.netlify.app/'
			},
			proj3: {
				projectTitle: 'Bezier Curves in Three.js',
				projectImg: require('@/assets/img/work/ThreeJSDemos/three_img_003.jpg'),
				projectDescription: 'I saw a site online that fixed the scene camera to a track. I thought about how that could be done, and came up with this demo. A camera fixed to a bezier curve all taken from Blender.',
				projectLink: 'https://colorroom.netlify.app/'
			},
		}
	},
	{
		"title": "StyleGan Art",
		"img":require('@/assets/img/work/StyleGanArt/aiart_img_001.jpg'),
		"headline": "A collection of art trained by me and generated using ML.",
		"tags":['Experiment', 'ML/AI'],
		"description": "These images are part of a short series I did around machine learning and style GANS. Each image was trained off of different series of images. Source images included patterns, classic art, and more. ",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/StyleGanArt/aiart_img_001.jpg'),
				projectDescription: '',
				projectLink: ''
			},
			proj2: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/StyleGanArt/aiart_img_002.jpg'),
				projectDescription: '',
				projectLink: ''
			},
			proj3: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/StyleGanArt/aiart_img_003.jpg'),
				projectDescription: '',
				projectLink: ''
			},
			proj4: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/StyleGanArt/aiart_img_004.jpg'),
				projectDescription: '',
				projectLink: ''
			},
		}
	},
	{
		"title": "ML Eyes",
		"img":require('@/assets/img/work/MLEyes/mleyes_img_001.jpg'),
		"headline": "Implementing Tensorflow.JS in an actual project.",
		"tags":['Experiment', 'AI/ML'],
		"description": "",
		"link": "https://mleyes.netlify.app",
	},
	{
		"title": "Gordon RamsAI",
		"img":require('@/assets/img/work/GordonRamsai/gr_img_001.jpg'),
		"headline": "You don't want this AI to review your food.",
		"tags":['Experiment', 'AI/ML'],
		"description": "When Open AI released GPT-2 I knew that I had to play with it. What better way than training a bot off the worst Gordon Ramsay quotes? To accompany this bot, I also trained a food recognition model that could recognize 50 different foods.",
		"link": "https://twitter.com/gordonramsai",
	},
	{
		"title": "Explore Your Playlists",
		"img": require('@/assets/img/work/SpotifyDemo/sd_img_001.jpg'),
		"headline": "A simple Spotify API tool.",
		"tags":['Experiment', 'Full Stack'],
		"description": "Keep in mind, this site is in ROUGH shape. It was mainly an excuse to try out heroku and the Spotify API.",
		"link": "https://mevnspotify.netlify.app/",
	},
	{
		"title": "AR Filters",
		"img": require('@/assets/img/work/ARFilters/arfilter_img_003.gif'),
		"headline": "Augmented reality lenses that live across Snapchat and Instagram.",
		"tags":['Client', 'AR', 'Instagram', 'Snapchat'],
		"description": "The one thing that put me on the trajectory to become a creative technologist was AR. These fun face filters were all created using Spark AR or Lens Studio. They taught me the fundamentals to programming and using 3d software.",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: 'Little Caesars - Pizza Box Scooter',
				projectImg: require('@/assets/img/work/ARFilters/arfilter_img_001.gif'),
				projectDescription: '',
				projectLink: ''
			},
			proj2: {
				projectTitle: 'Little Caesars - Pizza Pizza... Pizza',
				projectImg: require('@/assets/img/work/ARFilters/arfilter_img_002.gif'),
				projectDescription: '',
				projectLink: ''
			},
			proj3: {
				projectTitle: 'Little Caesars - Slices-N-Stix',
				projectImg: require('@/assets/img/work/ARFilters/arfilter_img_003.gif'),
				projectDescription: '',
				projectLink: ''
			},
			proj4: {
				projectTitle: 'Pampers - Show Yourself Some Love',
				projectImg: require('@/assets/img/work/ARFilters/arfilter_img_004.gif'),
				projectDescription: '',
				projectLink: ''
			},
			proj5: {
				projectTitle: 'Sherwin Williams - Find Your Love Color',
				projectImg: require('@/assets/img/work/ARFilters/arfilter_img_005.gif'),
				projectDescription: '',
				projectLink: ''
			},
			proj6: {
				projectTitle: 'Samsung - Fastest Interview Questions',
				projectImg: require('@/assets/img/work/ARFilters/arfilter_img_006.gif'),
				projectDescription: '',
				projectLink: ''
			},
		}
	},
	{
		"title": "The Boxcade",
		"img":require('@/assets/img/work/Boxcade/bc_img_003.jpg'),
		"headline": "An arcade machine built in Fusion and assembled using a laser cutter.",
		"tags":['Experiment', 'Fusion', '3D Design'],
		"description": "While I love spending time coding there is something special about making something tangible. For this project, I thought it would be fun to spend some time learning Fusion and actually assembling something by hand. The model was made designed in 3d and then cut out of cardboard using a laser cutter.",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/Boxcade/bc_img_001.png'),
				projectDescription: '',
				projectLink: ''
			},
			proj2: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/Boxcade/bc_img_002.jpg'),
				projectDescription: '',
				projectLink: ''
			},
			proj3: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/Boxcade/bc_img_003.jpg'),
				projectDescription: '',
				projectLink: ''
			},
			proj4: {
				projectTitle: '',
				projectImg: require('@/assets/img/work/Boxcade/bc_img_004.jpg'),
				projectDescription: '',
				projectLink: ''
			},
		}
	},
	{
		"title": "Portfolio Sites",
		"img":require('@/assets/img/work/PortfolioSites/ps_img_001.jpg'),
		"headline": "A brief history of my portfolio evolution.",
		"tags":['Experiment', 'ML/AI'],
		"description": "After three years in the technology industry, I'm still working on finally perfecting my portfolio. This site is currently adaptation number four. Instead of just completely leaving old sites behind, I've chosen to include links to the previous versions to reflect and see the growth between each.",
		"link": "",
		"additional":
		{
			proj1: {
				projectTitle: '2021 Portfolio',
				projectImg: require('@/assets/img/work/PortfolioSites/ps_img_001.jpg'),
				projectDescription: 'FPO',
				projectLink: 'https://aleckunk.netlify.app'
			},
			proj2: {
				projectTitle: 'A Portfolio Placeholder from 2020',
				projectImg: require('@/assets/img/work/PortfolioSites/ps_img_003.jpg'),
				projectDescription: 'FPO',
				projectLink: 'https://portfoliodemo2020.netlify.app/'
			},
			proj3: {
				projectTitle: 'My First HTML Portfolio',
				projectImg: require('@/assets/img/work/PortfolioSites/ps_img_002.jpg'),
				projectDescription: 'FPO',
				projectLink: 'https://aleckunkel.netlify.app/'
			},
		}
	},
 ]
 
 