<template>
		<div id="canvas"></div>
		<div class="temp-container">
    <div class="wrapper">
		<div class="plane">
			<!-- image that will be used as texture by our plane -->
			<img src="@/assets/img/site-identity/profile.jpg" crossorigin="" />
		</div>
    </div>
		</div>
</template>

<script>
import {Curtains, Plane} from 'curtainsjs';


export default {
	name: 'Curtains',
	components: {
	},
	mounted() {
	const planes = []

	// Sets up curtainsJS
	const curtains = new Curtains({
		container: "canvas",
		pixelRatio: Math.min(1.5, window.devicePixelRatio), // limit pixel ratio for performance
		watchScroll: false
	})

	// get our plane element
	const planeElement = document.getElementsByClassName("plane");

	// set our initial parameters (basic uniforms)
	const params = {
		vertexShaderID: "plane-vs", // our vertex shader ID
		fragmentShaderID: "plane-fs", // our fragment shader ID
		uniforms: {
			time: {
				name: "uTime", // uniform name that will be passed to our shaders
				type: "1f", // this means our uniform is a float
				value: 0,
			},
		},
	};

	// // create our plane using our curtains object, the HTML element and the parameters
  // const plane = new Plane(curtains, planeElement[0], params);

	// add our planes and handle them
	for(let i = 0; i < planeElement.length; i++) {
			planes.push(new Plane(curtains, planeElement[i], params));

			handlePlanes(i);
	}
	// handle all the planes
    function handlePlanes(index) {
        const plane = planes[index];

        // check if our plane is defined and use it
        plane.onReady(() => {
            // once everything is ready, display everything
            if(index === planes.length - 1) {
                document.body.classList.add("planes-loaded");
            }
        }).onRender(() => {
            // update the uniform
						plane.uniforms.time.value++; // update our time uniform value
        });
    }

  // // set up our basic methods
  // plane.onRender(() => { // fired at each requestAnimationFrame call
  //     plane.uniforms.time.value++; // update our time uniform value
  // });
	},
	methods: {
	}
}
</script>

<style lang="scss">
#canvas {
  /* make the canvas wrapper fits the document */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
	z-index:-1;
}

.wrapper {
	width: 100%;
	display: flex;
}

.temp-container {
	display: flex;
	@media screen and (max-width: $screen-portrait-max) {
		flex-direction: column;
	}
}

.plane {
  /* define the size of your plane */
  width: 80%;
  height: 400px;
	margin: 10px;
}

.plane img {
  /* hide the img element */
  display: none;
	width: 100%;
	border-radius: 10px;
	margin-top: 25px;
	margin-bottom:25px;
}
</style>
