<template>
	<div data-scroll-section class="about">
		<Bio/>
		<h1
		data-scroll
    data-scroll-class="visible"
		class="hidden">Where I've Been</h1>
		<Timeline
		data-scroll
    data-scroll-class="visible"
		class="hidden"/>
		<h1
		data-scroll
    data-scroll-class="visible"
		class="hidden">Things I've Written</h1>
		<Writing
		data-scroll
    data-scroll-class="visible"
		class="hidden"/>
	</div>
</template>

<script>
import Bio from '@/components/about/Bio'
import Timeline from '@/components/about/Timeline'
import Writing from '@/components/about/Writing'
import LocomotiveScroll from 'locomotive-scroll'

export default {
	name: 'About',
	components: {
		Bio,
		Timeline,
		Writing
	},
	mounted() {
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll5 = new LocomotiveScroll()
    });
  }
}
</script>

<style scoped lang="scss">
.hidden {
	opacity:0;
	transition: 1s;
}

.visible {
	opacity: 1 !important;
}

.about {
	display: flex;
	flex-direction: column;
	padding: 30px;
	h1 {
		font-size: 3rem;
		text-align: left;
	}
}
</style>
