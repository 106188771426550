<template>
	<div class="menu-items">
    <router-link to="/">
			<p class="magic-hover" v-on:click="toggleMenu">Home</p>
		</router-link>
		<router-link to="/projects">
			<p class="magic-hover" v-on:click="toggleMenu">Projects</p>
		</router-link>
		<router-link to="/about">
			<p class="magic-hover" v-on:click="toggleMenu">About</p>
		</router-link>
		<router-link to="/contact">
			<p class="magic-hover" v-on:click="toggleMenu">Contact</p>
		</router-link>
	</div>
</template>

<script>
import router from '@/router'

export default {
	name: 'Menu Items',
	components: {
	},
	methods: {
    toggleMenu() {
      let wrapperMenu = document.querySelector('.hamburger-icon')

      if (this.$store.getters.getMenuStatus === false) {
        wrapperMenu.classList.toggle('open')
        this.$store.commit('mutateMenuStatus', true)
        router.push({ path: '/menu' })
      }
      else {
        wrapperMenu.classList.toggle('open')
        this.$store.commit('mutateMenuStatus', false)
        router.push({ path: this.$store.getters.getPreviousRoute })
      }
    },
	}
}
</script>

<style scoped lang="scss">
.menu-items {
	z-index: 1000;
	display:flex;
	justify-content: center;
	align-items: center;
	height: 70%;
	@media screen and (max-width: $screen-portrait-max) {
		flex-direction: column;
	}
	p {
		display: inline-block;
		position: relative;
		color: $alt-color;
		margin:30px;
		font-size: 15px;
		transition: .2s;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			transform: scaleX(0);
			height: 3px;
			bottom: -5px;
			left: 0;
			background-color: $main-1;
			transform-origin: bottom right;
			transition: transform 0.25s ease-out;
		}
		&:hover:after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
}

</style>
