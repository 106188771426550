<template>
	<div class="stories">
    <a href="https://aleckunkel.medium.com/scraping-tiktok-data-using-python-a2fde787f160"><h2>Scrape TikTok Data Using Python</h2></a>
    <a href="https://aleckunkel.medium.com/one-week-with-nvidia-omniverse-d52684580e35"><h2>One Week With Nvidia Omniverse</h2></a>
    <a href="https://aleckunkel.medium.com/building-the-ultimate-cloud-based-blender-asset-library-df8c7f881d35"><h2>Building the Ultimate Cloud-Based Blender Asset Library</h2></a>
    <a href="https://aleckunkel.medium.com/whats-in-store-for-2022-9e0fe6f761c"><h2>What’s in Store for 2022</h2></a>
    <a href="https://medium.com/madebymckinney/eyes-in-the-back-of-my-head-an-ai-alternative-to-looking-over-your-shoulder-c43045d3210"><h2>Eyes in the Back of My Head: An AI Alternative to Looking Over Your Shoulder</h2></a>
    <a href="https://medium.com/madebymckinney/happy-little-ai-trees-bob-ross-meets-nvidia-canvas-d8a2117eb71b"><h2>Happy Little AI Trees: Bob Ross Meets Nvidia Canvas</h2></a>
    <a href="https://medium.com/madebymckinney/epic-games-is-building-something-special-bba386646bfd"><h2>Epic Games is Building Something Special</h2></a>
    <a href="https://medium.com/madebymckinney/metahuman-creator-first-impressions-142243d44177"><h2>Metahuman Creator First Impressions</h2></a>
	</div>
</template>

<script>

export default {
	name: 'Bio',
	components: {
	},
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.stories {
  display: flex;
  padding-top: 20px;
  padding-bottom:40px;
  align-items: baseline;
	text-align: left;
  flex-direction: column;
  a {
    color: white;
    padding-bottom: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
