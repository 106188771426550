import {createStore} from 'vuex'

export default createStore({
	state: {
			menuStatus: false,
			previousRoute: '',
			activeRoute: ''
	},
	mutations: {
		mutateMenuStatus(state, payload) {
			state.menuStatus = payload;
		},
		mutatePreviousRoute(state, payload) {
			state.previousRoute = payload;
		},
		mutateActiveRoute(state, payload) {
			state.activeRoute = payload;
		},
	},
	getters: {
		getMenuStatus(state) {
			return state.menuStatus;
		},
		getPreviousRoute(state) {
			return state.previousRoute;
		},
		getActiveRoute(state) {
			return state.activeRoute;
		},
	},
});
