<template>
	<div data-scroll-section class="contact">
		<Main
		data-scroll
    data-scroll-class="visible"
		class="hidden"/>
	</div>
</template>

<script>
import Main from '@/components/contact/Main'
import LocomotiveScroll from 'locomotive-scroll'

export default {
	name: 'Contact',
	components: {
		Main
	},
	mounted() {
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll5 = new LocomotiveScroll()
    });
  }
}
</script>

<style scoped lang="scss">
.contact {
	text-align: left;
	width: 100%;
	p {
	font-weight: 300;
	line-height: 1.5;
	font-size: 16px;
	opacity: .9;
	text-align: left;
	}
}

.hidden {
	opacity:0;
	transition: 1s;
}

.visible {
	opacity: 1 !important;
}
</style>
