<template>
	<div data-scroll-section >
		<div 
		class="bio hidden"
		data-scroll
    data-scroll-class="visible">
			<div class="column1">
				<Curtains />
			</div>
			<div class="column2">
				<div
				id="cycle"
				title="Creative Technologist" 
				class="special-name">
					Creative Technologist
				</div>
				<p>
					I couldn't decide what I wanted to do so I decided to do it all. 
					I am multidisciplinary creative technologist that gets excited about making 
					technology accessible and engaging for all audiences. 
				</p>
					<br>
				<p>
					After graduating from University of North Carolina Wilmington
					I began my career at McKinney as a digital generalist. A unique position that taught me the fundementals of the 
					advertising industry. Since then I've fallen in love with creating experiential work.
				</p>
					<br>
				<p>
					At the beginning of 2021 I had the opportunity to build the brand experience team from the ground up,
					and have been there since.
				</p>
					<br>
				<p>
					<b>Here is a list of things that I enjoy working with:</b>
					<br>
					XR, AR, VR, Unreal Engine, Unity, Blender, 
					Substance Painter, Touch Designer, WebGl, Three.js,
					Nvidia Omniverse, Spark AR, Snapchat Studio,
					Web 3.0, HTML, CSS, Javascript, Vue, React, 
					AI/ML, Style Transfer, NLP, Python, Data Viz,
					Data Studio, Google Analytics, 
					C#, C++, Adobe Creative Suite
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Curtains from '@/components/about/Curtains'

export default {
	name: 'Bio',
	components: {
		Curtains
	},
  mounted() {
    const textList = ["Creative Technologist", "3D Animator", "Developer", "Data Analyst", "XR Developer", "Creative", "AI/ML Developer", "Web 3.0"];
    const cycle = document.querySelector("#cycle");

    let i = 0;

    const cycleText = () => {
      cycle.innerHTML = textList[i];
      cycle.title = textList[i];

      i = ++i % textList.length;
    };
    cycleText();

    setInterval(cycleText, 1500);

		this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll5 = new LocomotiveScroll()
    });
  }
}
</script>

<style scoped lang="scss">
.bio {
  display: flex;
  padding-top: 20px;
  padding-bottom:40px;
  align-items: center;
	text-align: left;
	@media screen and (max-width: $screen-portrait-max) {
		flex-direction: column;
	}
	.column1 {
		width: 100%;
		.profile-img {
			width: 80%;
			border-radius: 10px;
			margin-top: 25px;
			margin-bottom:25px;
		}
	}
	.column2 {
		width:100%;
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: space-between;
		p {
			line-height:20px;
		}
	}
}

.special-name{
  animation: glitch 1s linear infinite;
	font-size: 2em;
	font-weight: bold;
	padding-bottom:25px;
	@media screen and (max-width: $screen-portrait-max) {
		font-size: 1.5em;
	}
	&:before{
		content: attr(title);
		position: absolute;
		left: 0;
		animation: glitchTop 1s linear infinite;
		clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
	}
	&:after{
		content: attr(title);
		position: absolute;
		left: 0;
		animation: glitchBotom 1.5s linear infinite;
		clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
	}
}

.hidden {
	opacity:0;
	transition: 1s;
}

.visible {
	opacity: 1 !important;
}
</style>
