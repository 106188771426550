<template>
	<div
  data-scroll-section
  class="project-title">
    <ul
    class="hidden"
    data-scroll
    data-scroll-class="visible">
      <li class="one"><router-link to="/projects">Projects</router-link></li>
      <li class="two"><router-link to="/dailies">Dailies</router-link></li>
      <hr />
    </ul>
	</div>
</template>

<script>

export default {
	name: 'Nav',
	components: {
	},
  mounted() {
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll3 = new LocomotiveScroll()
    });
  }
}
</script>

<style scoped lang="scss">
.hidden {
	opacity:0;
	transition: 1s;
}

.visible {
	opacity: 1 !important;
}

.project-title {
  width: 50%;
  margin: 0 auto;
  @media screen and (max-width: $screen-portrait-max) {
    width: 100%;
  }
}

ul li {
  display: inline;
  text-align: center;
}

a {
  display: inline-block;
  width: 25%;
  padding: .25rem 0;
  color: white;
  font-weight: bold;
}

.one:hover ~ hr {
  margin-left: 25%;
}

hr {
  margin-left: 50%;
  margin-top: 0;
  height: .25rem;
  width: 25%;
  background: $main-1;
  border: none;
  transition: .3s ease-in-out;
}
</style>
