<template>
	<div class="contact">
		<div class="contact-prompt">
			<div 
			title="Say Hello!" 
			class="special-name">
			Say Hello!
			</div>
		</div>
		<div class="contact-actions">
			<div class="individual-contact">
				<p>Send over an email ✉️</p>
				<a href="mailto: iamkunkel@gmail.com?subject=Saying Hello&body=" target="_blank">
					iamkunkel@gmail.com
				</a>
			</div>
			<div>
				<p>Slide into my DMs 📱</p>
				<SocialIcons/>
			</div>
		</div>
	</div>
</template>

<script>
import SocialIcons from '@/components/contact/partials/SocialIcons'

export default {
	name: 'Main',
	components: {
		SocialIcons
	}
}
</script>

<style scoped lang="scss">
.special-name{
  animation: glitch 1s linear infinite;
	font-size: 4rem;
	font-weight: bold;
	padding-bottom:15px;
	@media screen and (max-width: $screen-portrait-max) {
		font-size: 3.5em;
	}
	&:before{
		content: attr(title);
		position: absolute;
		left: 0;
		animation: glitchTop 1s linear infinite;
		clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
	}
	&:after{
		content: attr(title);
		position: absolute;
		left: 0;
		animation: glitchBotom 1.5s linear infinite;
		clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
	}
}

.contact {
	display: flex;
	padding: 30px;
	height: 60vh;
  justify-content: space-between;
	align-items: center;
	@media screen and (max-width: $screen-portrait-max) {
		flex-direction:column;
		justify-content: center;
	}
	.contact-prompt {
		display:flex;
		justify-content: center;
	}
	div {
		width: 100%;
	}
	h1 {
		font-size: 3rem;
	}
	p {
		font-size: 1rem;
		font-weight: 600;
	}
	a {
		text-decoration: underline;
		font-weight: bold;
		color: white;
		font-size: 1.25rem;
	}
	.individual-contact {
		padding-bottom:25px;
	}
}
</style>
