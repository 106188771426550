<template>
	<div 
	data-scroll-section
	class="home-main">
	<div 		
	class="hidden"
	data-scroll
	data-scroll-class="visible">
		<p>
			Hey There 👋! My name is
		</p>
		<div
		title="Alec Kunkel" 
		class="special-name">
			Alec Kunkel
		</div>
		<p class="home-copy">
			Durham, NC. 📍
		</p>
		<p class="home-copy">
			Creative Technolgoist
			<span>
				<a href="https://mckinney.com" target="_blank">
					<img class="mck-logo" src="@/assets/img/logos/mckLogo.svg">
				</a>
			</span>
		</p>
		<p class="home-copy">
			Obsessed with finding creative applications for technology.
		</p>
		<p class="home-copy">
			Currently on a 3d art kick. Specifically
			Unreal Engine and how it can be leveraged in virtual 
			production. 
		</p>
	</div>
	</div>
</template>

<script>
import LocomotiveScroll from 'locomotive-scroll'

export default {
	name: 'Main',
	components: {
	},
	mounted() {
    this.$nextTick(function() {
			// eslint-disable-next-line
			const scroll = new LocomotiveScroll()
      // _self.initLocoScroll();
    });
	}
}
</script>

<style scoped lang="scss">
.hidden {
	opacity:0;
	transition: 1s;
}

.visible {
	opacity: 1 !important;
}

.special-name{
  animation: glitch 1s linear infinite;
	font-size: 5em;
	font-weight: bold;
	@media screen and (max-width: $screen-portrait-max) {
		font-size: 2.5em;
	}
	&:before{
		content: attr(title);
		position: absolute;
		left: 0;
		animation: glitchTop 1s linear infinite;
		clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
	}
	&:after{
		content: attr(title);
		position: absolute;
		left: 0;
		animation: glitchBotom 1.5s linear infinite;
		clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
	}
}

.home-main {
	text-align: left;
	width: 100%;
	p {
	font-weight: 300;
	line-height: 1.5;
	font-size: 16px;
	opacity: .9;
	text-align: left;
	}
	.home-copy{
		display: flex;
	}
	span {
		display:flex;
	}
	.mck-logo {
		height: 20px;
		padding-left: 8px;
	}
	a {
		padding: 0;
		margin: 0;
	}
}
</style>
