<template>
  <transition name="slide" appear>
    <div class="modal" v-if="show">
      <div class="modal-backdrop" 
      @click="closeModal()"/>
      <div class="modal-close"
      @click="closeModal()">
      <img class="close-icon" src="@/assets/img/logos/closeIcon.svg">
      </div>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="header"/>
          </div>
          <div class="modal-gallery">
            <slot name="gallery"/>
          </div>

          <div class="modal-body">
            <slot name="body"/>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "WorkSidebar",
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position:fixed;
  display:flex;
  justify-content: center;
  align-items:center;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &-close{
    position: fixed;
    z-index: 1000;
    top: 50px;
    right: 50px;
    color: white;
    cursor: pointer;
    font-size: 30px;
    .close-icon {
      width: 25px;
      height: 25px;
      transition: .25s;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
  &-dialog {
    position: absolute;
    bottom:0;
    background-color: $primary-color-light;
    width: 600px;
    height: 100%;
    right:0;
    display: flex;
    flex-direction: column;
    z-index: 2;
    overflow-y: auto;
    @media screen and (max-width: $screen-portrait-max) {
      width: 100%;
    }
  }
  &-content{
    height:100%;
    padding: 50px;
    padding-top:15%;
    display:flex;
    flex-direction:column;
    @media screen and (max-width: $screen-portrait-max) {
      padding-top:20%;
    }
  }
  &-header {
    display: flex;
  }
  &-gallery {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 100px;
  }
}

// Handles slide in animation
.slide-enter-to {
  transform: translatex(100%);
}

.slide-enter {
  transform: translatex(100%);
}

.slide-enter-active,
.slide-leave-active{
  transform: translatex(100%);
}
</style>